
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Grazie per avermi contattato, ti risponderò il prima possibile.`}</p>
    <p><em parentName="p">{`Emanuele`}</em>{`.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    